<template>
  <div class="processManage">
    <!-- <navi-gation /> 客户端日志 -->
    <dataPbulic @dataPublic="dataPublic">
      <!-- 日期组件 -->
      <el-col :span="3">
        <el-input
          v-model="queryObj.deviceName"
          size="small"
          :placeholder="$t('MobileOperations.ClientLogs.placeholder')"
          @keyup.enter.native="searchList"
        ></el-input>
      </el-col>
      <el-col :span="4">
        <el-button size="small" type="primary" @click="searchList">{{
          $t("public.Inquire")
        }}</el-button>
      </el-col>
    </dataPbulic>
    <el-row :gutter="4" class="elRow">
      <!-- <div @click="maskLayer" class="callenia" style="
            width: 55px;
            height: 0px;
            background: rgba(255,  255,  255, 0.1);
            position: absolute;
            top: 10px;
            left: 11px;
            z-index: 2;
            font-size: 12px;
          ">
          <i class="el-icon-caret-bottom" style="
              position: absolute;
              top: 0px;
              left: 34px;
              color: #969696;
              font-size: 14px;
            "></i>
        </div> -->
      <el-table
        ref="multipleTable"
        :data="dictData"
        stripe
        size="mini"
        tooltip-effect="dark"
        class="sort_table"
        :default-sort="{ prop: 'createTime', order: 'descending' }"
        @sort-change="sort"
        @selection-change="handleSelectionChange"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
        style="width: 100%"
      >
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column
          header-align="left"
          align="left"
          prop="deviceName"
          :label="$t('public.DeviceName')"
          sortable="custom"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          header-align="left"
          align="left"
          prop="loginName"
          :label="$t('public.Username')"
          sortable="custom"
        >
        </el-table-column>
        <el-table-column
          header-align="left"
          align="left"
          prop="userName"
          :label="$t('public.Name')"
          sortable="custom"
        >
        </el-table-column>
        <el-table-column
          header-align="left"
          align="left"
          prop="groupName"
          :label="$t('public.Section')"
          sortable="custom"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            <span v-if="row.groupName">
              {{ row.groupName.substr(groupFullNameShowByIndex) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="left"
          align="left"
          prop="osType"
          :label="$t('public.OperatingSystem')"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <template v-if="row.osType == 20"> Android </template>
            <template v-if="row.osType == 10"> iOS </template>
            <template v-if="row.osType == 30"> Windows </template>
            <template v-if="row.osType == 40"> Linux </template>
          </template>
        </el-table-column>
        <el-table-column
          header-align="left"
          align="left"
          prop="status"
          :label="$t('MobileOperations.ClientLogs.Status')"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <template v-if="row.status == 0">
              {{ $t("MobileOperations.ClientLogs.Extracting") }}
            </template>
            <template v-if="row.status == 1">
              {{ $t("MobileOperations.ClientLogs.Extracted") }}
            </template>
            <template v-if="row.status == 2">
              {{ $t("MobileOperations.ClientLogs.ExtractionFailed") }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="startTime"
          :label="$t('public.StartTime')"
          header-align="left"
          align="left"
          show-overflow-tooltip
          sortable="custom"
        >
          <template v-slot="{ row }">
            <template v-if="row.startTime">
              {{ row.startTime | dateformat }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="endTime"
          :label="$t('public.EndTime')"
          header-align="left"
          align="left"
          show-overflow-tooltip
          sortable="custom"
        >
          <template v-slot="{ row }">
            <template v-if="row.endTime">
              {{ row.endTime | dateformat }}
            </template>
          </template>
        </el-table-column>
        <!-- <el-table-column
            header-align="left"
            align="left"
            prop="creator"
            label="创建者"
            sortable
          >
          </el-table-column> -->
        <el-table-column
          prop="createTime"
          :label="$t('MobileOperations.ClientLogs.CreationTime')"
          header-align="left"
          align="left"
          show-overflow-tooltip
          sortable="custom"
        >
          <template v-slot="{ row }">
            <template v-if="row.createTime">
              {{ row.createTime | dateformat }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('public.Controls')"
          width="120"
          header-align="left"
          align="left"
        >
          <template v-slot="{ row }">
            <span class="action_icon">
              <img
                v-if="row.status == 1"
                @click="downFile(row)"
                src="@/images/icon_download.png"
                alt=""
                style="width: 15px; height: 15px; cursor: pointer"
              />
            </span>
            <span class="action_icon">
              <img
                :title="$t('public.Delete')"
                @click="deletes(row)"
                src="@/images/icon_del.png"
                alt=""
                style="width: 15px; height: 15px; cursor: pointer"
              />
            </span>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :page="queryObj.currentPage"
        :limit="queryObj.pageSize"
        :total="totalCount"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      />
    </el-row>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    /><!-- 提示消息组件 -->
    <!-- </div> -->
  </div>
</template>
<script>
import dataPbulic from "@/components/datePublic/index";
import dialogInfo from "@/components/promptMessage/index";
import naviGation from "@/components/hearder/index";
import pagination from "@/components/pagination/page.vue";
export default {
  components: { pagination, naviGation, dialogInfo, dataPbulic },
  data() {
    return {
      groupFullNameShowByIndex: "", //用户部门截取的下标
      titleInfo: "",
      titleInfo: "",
      dictData: [],
      multipleSelection: [],
      queryObj: {
        startTime: "",
        endTime: "",
        currentPage: 1,
        pageSize: 10,
        orderColume: "createTime",
        orderRule: "DESC",
        deviceName: "",
      },
      totalCount: 0,
      // 遮罩全选
      isCheckShow: false,
      visibaelFlag: false,
    };
  },
  computed: {
    hasSeleted() {
      return this.multipleSelection && this.multipleSelection.length;
    },
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.tableList();
  },
  methods: {
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    sort(column) {
      // console.log(column.order, column.prop, "----");
      if (column.order === "ascending") {
        this.queryObj.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.queryObj.orderRule = "DESC";
      } else {
        this.queryObj.orderRule = "";
      }
      this.queryObj.orderColume = column.prop;
      this.tableList();
    },
    dataPublic(val) {
      this.queryObj.startTime = val.startTime;
      this.queryObj.endTime = val.endTime;
    },
    //请求列表
    async tableList() {
      const res = await this.$axios.post(
        "/httpServe/device/log/list",
        this.queryObj,
        true
      );
      this.$nextTick(() => {
        this.dictData = res.data.content;
        this.totalCount = res.data.total;
      });
    },
    async deleteBaths(row) {
      let param = {
        ids: [row.id],
      };
      const res = await this.$axios.post(
        "/httpServe/device/log/delete",
        param,
        true
      );
      if (res.code == 200) {
        this.$message({
          message: this.$t("public.SuccessfullyDeleted"),
          type: "success",
          offset: 100,
        });
        this.tableList();
      }
    },
    searchList() {
      this.tableList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.queryObj.currentPage = page;
      this.queryObj.pageSize = limit;
      // 调用查询方法
      this.tableList();
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true;
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false;
    },
    //当前页全选
    curSelection() {
      this.disabled = false;
      this.$refs.multipleTable.toggleAllSelection();
    },
    //所有页全选
    toggleAllSelection() {
      this.disabled = false;
      this.$refs.multipleTable.toggleAllSelection();
    },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
        this.disabled = false;
      }
      this.isCheckShow = true;
    },
    async downFile(row) {
      var param = {
        path: row.logFile,
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      // console.log(res.data)
      await this.$axios.downloadFile(res.data);
    },
    //删除
    deletes(row) {
      this.$confirm(
        this.$t("MobileOperations.ClientLogs.MessagePort"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(() => {
          this.deleteBaths(row);
        })
        .catch(() => {
          return false;
        });
    },
    handleClose() {
      this.visibaelFlag = false;
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.mt15 {
  padding: 10px 10px;
}
.processManage .callenia {
  height: 0;
}
.processManage {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
